import React from "react";
import styled from "styled-components";
import posed, { PoseGroup } from "react-pose";
import PropTypes from "prop-types";
import menu_icon from "../images/round-menu-24px.svg";
import close_icon from "../images/round-close-24px.svg";
import { media } from "../css/theme";
import guide_icon from "../images/round-info-24px.svg";
import features_icon from "../images/round-extension-24px.svg";
import pricing_icon from "../images/round-credit_card-24px.svg";
import about_icon from "../images/round-chat-24px.svg";
import facebook from "../images/facebook-box.svg";
import twitter from "../images/twitter-box.svg";
import medium from "../images/medium-box.svg";
import linkedin from "../images/linkedin-box.svg";
import Link from "../i18n/Link";
import SocialMedia from "../components/SocialMedia";

const ItemContainer = styled.div`
  padding: 8px;
  display: flex;
  img {
    margin-right: 8px;
  }
  h4 {
    font-weight: 300;
    line-height: 1.2;
    margin: 0px;
  }
  p {
    margin: 0px;
    line-height: 1;
    padding: 0px;
    font-size: 16px;
    font-weight: 400;
  }
  a {
    display: inherit;
  }
`;

const MenuItem = ({ icon, title, caption, to, toggleShowMenu }) => {
  return (
    <ItemContainer onClick={toggleShowMenu}>
      <Link to={to} activeClassName="pathIsCurrent">
        <img src={icon} alt="icon" aria-hidden="true" />
        <div>
          <h4>{title}</h4>
          <p>{caption}</p>
        </div>
      </Link>
    </ItemContainer>
  );
};
MenuItem.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  caption: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired
};

const PosedDiv = posed.div({
  enter: {
    x: 0,
    y: 0,
    opacity: 1
  },
  preEnter: {
    x: 80,
    y: -80,
    opacity: 0
  }
});

const Container = styled(PosedDiv)`
  z-index: 10;
  border-radius: 8px;
  position: fixed;
  top: 0;
  right: 0;
  margin: 16px;
  background-color: white;
  padding: 8px;
  width: calc(100% - 32px);
  max-width: 340px;
  nav {
    /* social media buttons */
    display: flex;
    margin: 8px 40px 0px;
    justify-content: space-around;
  }
  ${props => props.theme.card2};
`;

const MenuIcon = styled.img`
  cursor: pointer;
  width: 40px;
  position: fixed;
  top: 10px; /* to center in 60px banner */
  right: 16px;
  z-index: 3;
  ${media.tablet`display: none`};
`;

const CloseIcon = styled.img`
  position: absolute;
  width: 32px;
  top: 4px;
  right: 4px;
  z-index: 5;
  cursor: pointer;
`;

class MenuPopup extends React.Component {
  state = {
    showMenu: false
  };

  toggleShowMenu = () => {
    this.setState({ showMenu: !this.state.showMenu });
  };

  render() {
    const { content } = this.props;
    return (
      <>
        <MenuIcon onClick={this.toggleShowMenu} src={menu_icon} />
        <PoseGroup preEnterPose="preEnter">
          {this.state.showMenu && (
            <Container key="menu">
              <CloseIcon src={close_icon} onClick={this.toggleShowMenu} />
              <MenuItem
                to="/features"
                icon={features_icon}
                title={content.features_btn}
                toggleShowMenu={this.toggleShowMenu}
                caption={content.features_btn_caption}
              />
              <MenuItem
                to="/pricing"
                icon={pricing_icon}
                title={content.pricing_btn}
                toggleShowMenu={this.toggleShowMenu}
                caption={content.pricing_btn_caption}
              />
              <MenuItem
                to="/guide"
                icon={guide_icon}
                title={content.guide_btn}
                toggleShowMenu={this.toggleShowMenu}
                caption={content.guide_btn_caption}
              />
              <MenuItem
                to="/about"
                icon={about_icon}
                title={content.about_btn}
                toggleShowMenu={this.toggleShowMenu}
                caption={content.about_btn_caption}
              />
              <nav>
                <SocialMedia
                  alt="facebook"
                  icon={facebook}
                  to="https://www.facebook.com/canvis.mapping/"
                />
                <SocialMedia
                  alt="twitter"
                  icon={twitter}
                  to="https://twitter.com/canvis_mapping"
                />
                <SocialMedia
                  alt="medium"
                  icon={medium}
                  to="https://medium.com/@canvis"
                />
                <SocialMedia
                  alt="linkedIn"
                  icon={linkedin}
                  to="https://www.linkedin.com/company/canvisapp/"
                />
              </nav>
            </Container>
          )}
        </PoseGroup>
      </>
    );
  }
}

export default MenuPopup;
MenuPopup.propTypes = {
  content: PropTypes.shape({
    about_btn: PropTypes.string.isRequired,
    about_btn_caption: PropTypes.string.isRequired,
    features_btn: PropTypes.string.isRequired,
    features_btn_caption: PropTypes.string.isRequired,
    guide_btn_caption: PropTypes.string.isRequired,
    pricing_btn_caption: PropTypes.string.isRequired,
    guide_btn: PropTypes.string.isRequired,
    pricing_btn: PropTypes.string.isRequired
  }).isRequired
};
