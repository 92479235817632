import React from "react";
import styled from "styled-components";
import banner_thin from "../images/banner_thin.svg";
import MenuPopup from "./MenuPopup";
import PropTypes from "prop-types";
import DesktopMenu from "./DesktopMenu";
import { media } from "../css/theme";
import Link from "gatsby-link";

const Nav = styled.nav`
  max-width: 1000px;
  width: 100%;
  margin: 0px auto;
  display: flex;
  justify-content: space-between;
`;

const StyledLink = styled(Link)`
  width: 195px;
  > img {
    display: block;
    width: 100%;
    height: auto;
  }
  ${media.tablet`
  width: 250px;
  display: block;
  `};
`;

const Banner = () => (
  <StyledLink to="/">
    <img width="195" height="52" src={banner_thin} alt="canvis.app logo" />
  </StyledLink>
);

class Header extends React.Component {
  render() {
    const { content } = this.props;
    return (
      <Nav>
        <Banner />
        <MenuPopup content={content} />
        <DesktopMenu content={content} />
      </Nav>
    );
  }
}

export default Header;

Header.propTypes = {
  content: PropTypes.object.isRequired
};
