import React from "react";
import Helmet from "react-helmet";
import Header from "./Header";
import Footer from "./Footer";
import styled, { ThemeProvider } from "styled-components";
import texture from "../images/fabric-plaid.png";
import PropTypes from "prop-types";
import theme, { media } from "../css/theme";

const Background = styled.div`
  display: flex;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(
      170deg,
      rgba(38, 166, 154, 0.2) 30%,
      rgba(255, 167, 38, 0.2) 50%,
      rgba(244, 67, 54, 0.2) 75%
    ),
    url(${texture});
`;

const Main = styled.main`
  max-width: 1600px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 8px;
  ${media.large_phone`
  padding: 16px;
  `};
  ${media.small_tablet`
  padding: 24px;
  `};
  ${media.tablet`
  padding: 32px;
  `};
`;

const Layout = ({ siteTitle, metaDescription, content, children, locale }) => {
  return (
    <>
      <Helmet>
        <html lang={locale} />
        <title>{siteTitle}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>
      <Background>
        <ThemeProvider theme={theme}>
          <Main>
            <Header content={content} />
            {children}
            <Footer content={content} />
          </Main>
        </ThemeProvider>
      </Background>
    </>
  );
};

export default Layout;

Layout.propTypes = {
  siteTitle: PropTypes.string.isRequired,
  metaDescription: PropTypes.string.isRequired,
  content: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired
};
