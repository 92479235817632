import React from "react";
import { I18n } from "./I18nContext";

// HOC to give a component access to the context's locale, locales, and update func.
const withLocale = Component => {
  return class extends React.Component {
    render() {
      return (
        <I18n.Consumer>
          {context => {
            return (
              <Component
                locale={context.locale}
                locales={context.locales}
                updateLocale={context.updateLocale}
                {...this.props}
              />
            );
          }}
        </I18n.Consumer>
      );
    }
  };
};

export default withLocale;
