import styled from "styled-components";
import { media } from "../css/theme";
import posed from "react-pose";

export const HR = styled.div`
  width: 120px;
  margin: 0 0 16px;
  height: 2px;
  background: linear-gradient(to right, #f44336, #ffa726, #26a69a);
`;

const AnimatedArticle = posed.article({
  enter: {
    y: 0,
    opacity: 1
  },
  exit: {
    y: 50,
    opacity: 0
  }
});

export const WhiteContentBox = styled(AnimatedArticle)`
  background-color: white;
  border-radius: 16px;
  padding: 16px;
  align-self: flex-start;
  margin-top: 16px;
  max-width: 1054px;
  > ${HR} {
    margin: 32px 0px;
  }
  > h1 {
    margin: 16px 0px;
  }
  ${media.tablet`
    margin: 0px 0px 16px 16px;
    width: 100%;
    padding: 24px;
  `};
  ${media.desktop`
    margin: 0px 16px;
  `};
  ${p => p.theme.card1};
`;

export const GuideContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  ${media.tablet`
    flex-direction: initial;
    align-items: stretch;
  `};
`;

// used to separate each row of content on all pages
// using responsive margins.
export const Divider = styled.section`
  width: 100%;
  max-width: 1000px;
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  > button {
    width: 100%;
    margin: 0px;
  }
  ${media.small_tablet`
  margin: 3rem 0;
  padding: 16px;
  `};
  ${media.tablet`
    margin: 6rem 0;
    > button {
      width: 33%;
    };
  `};
`;

export const PageContainer = styled.div`
  max-width: 1000px;
  width: 100%;
`;

export const PageHeader = styled.div`
  max-width: 650px;
  margin: 8px;
  h1,
  h4 {
    margin: 8px 0px;
  }
  > div {
    display: flex;
    margin-top: 1rem;
  }
  button {
    width: 50%;
    margin: 4px;
  }
  ${media.large_phone`
    margin: 16px;
  `};
  ${media.small_tablet`
  margin: 1.5rem;
  font-size: 2.5rem;
  button {
    width: 200px;
  }
  `};
  ${media.tablet`
  margin: 2rem;
  `};
  ${media.desktop`
  margin: 2.5rem;
  `};
`;

export const ButtonFont = styled.span`
  font-size: 0.777rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.75px;
  user-select: none;
`;

export const StyledPageTOC = styled.div`
  p {
    padding: 0px;
    line-height: 1;
    margin-bottom: 12px;
  }
  a {
    color: ${props => props.theme.light_black};
    font-weight: 500;
  }
  li {
    margin-bottom: 12px;
  }
  > ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
    a {
      /* all #title */
      font-size: 0.8rem;
    }
    > li > ul {
      /* all ##subtitle */
      list-style: none;
      padding: 0 0 0 16px;
      a {
        font-size: 0.7rem;
      }
      ul {
        display: none;
      }
    }
  }
`;
