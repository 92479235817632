import React from "react";
import { HR } from "./common";
import styled from "styled-components";
import Link from "../i18n/Link";
import { Link as BasicLink } from "gatsby";
import { media } from "../css/theme";

const Container = styled.footer`
  margin: auto 8px 8px 8px;
  padding-top: 3rem;
  ${HR} {
    margin: 0px auto;
  }
  > nav {
    display: flex;
    flex-wrap: wrap;
  }
  ${media.small_tablet`
    margin: auto 16px 16px 16px;
    > nav {
      justify-content: center;
    }
    `};
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px 8px;
  h4 {
    margin-top: 0;
  }
  a {
    margin: 4px 0;
  }
  ${media.tablet`margin: 16px 16px;`};
  ${media.desktop`margin: 16px 24px;`};
`;

const Footer = ({ content }) => {
  return (
    <Container>
      <HR />
      <nav>
        <Col>
          <h4>{content.footer_left_title}</h4>
          <Link to="/guide/case-studies">{content.footer_left_first}</Link>
          <Link to="/guide">{content.footer_left_second}</Link>
          <Link to="/pricing">{content.footer_left_third}</Link>
        </Col>
        <Col>
          <h4>{content.footer_mid_title}</h4>
          <Link to="/about">{content.footer_mid_first}</Link>
          <Link to="/privacy">{content.footer_mid_second}</Link>
          <Link to="/terms">{content.footer_mid_third}</Link>
          {/* this only gets created on build */}
          <a href="/sitemap.xml">{content.footer_mid_fourth}</a>
        </Col>
        <Col>
          <h4>{content.footer_right_title}</h4>
          <Link to="/about">{content.footer_right_first}</Link>
          <a href="https://medium.com/@canvis">{content.footer_right_third}</a>
        </Col>
      </nav>
    </Container>
  );
};
export default Footer;
