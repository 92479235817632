import React from "react";
import PropTypes from "prop-types";

const SocialMedia = ({ to, icon, alt, className }) => (
  <a href={to} className={className}>
    <img src={icon} alt={alt} />
  </a>
);
SocialMedia.propTypes = {
  to: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired
};

export default SocialMedia;
