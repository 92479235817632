import { css } from "styled-components";

const sizes = {
  // style "starts at" (min-width)
  large_phone: 325,
  small_tablet: 576,
  tablet: 768,
  desktop: 992
};

// Iterate through the sizes and create a media template
// add styles when screen > 600 by simply calling ${media.tablet`..styles`}
export const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${sizes[label]}px) {
      ${css(...args)};
    }
  `;

  return acc;
}, {});

const theme = {
  card1: "box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
  card2: "box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
  card3: "box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
  card4:
    "box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
  card5:
    "box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)",
  red_1: "#F44336", // sharper
  red_2: "#EF5350", // a bit faded (see figma)
  teal_1: "#009688",
  teal_2: "#26A69A",
  light_black: "#424242"
};

export default theme;
