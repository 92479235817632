import React from "react";
import { graphql } from "gatsby";
import Layout from "./Layout";
// every query needs a layout object and a content object.

// don't actually need to import query fragments, gatsby handles that automatically
export const tocFragment = graphql`
  fragment TOC on AirtableConnection {
    edges {
      node {
        data {
          Title
          Path
          Markdown {
            childMarkdownRemark {
              html
            }
          }
          Pages {
            data {
              Path
              Title
            }
          }
        }
      }
    }
  }
`;

export const staticFragment = graphql`
  fragment static on AirtableConnection {
    edges {
      node {
        data {
          ID
          Text
          Markdown {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;

export const layoutFragment = graphql`
  fragment layout on AirtableConnection {
    edges {
      node {
        data {
          ID
          Text
        }
      }
    }
  }
`;
/* eslint-disable react/display-name */
const withLayout = Component => {
  return class extends React.PureComponent {
    constructor(props) {
      super(props);
      const { content, layoutContent, ...otherQueries } = this.flattenQuery(
        props.data
      );
      this.content = content;
      this.layoutContent = layoutContent;
      this.otherQueries = otherQueries;
    }

    flattenQuery = data => {
      const { layout, content, ...rest } = data;
      const newObj = {
        layoutContent: {},
        content: {},
        ...rest
      };
      // always generate a layoutContent object
      layout.edges.forEach(({ node }) => {
        newObj.layoutContent[node.data.ID] = node.data.Text
          ? node.data.Text
          : node.data.Markdown
          ? node.data.Markdown.childMarkdownRemark.html
          : console.error("no content found for", node.data.id);
      });
      if (content.edges.length === 1) {
        // represents a Page or Section in the knowledge base;
        const query = data.content.edges[0].node.data;
        newObj.content.Title = query.Title;
        newObj.content.Markdown = query.Markdown.childMarkdownRemark.html;
        newObj.content.pageTOC =
          query.Markdown.childMarkdownRemark.tableOfContents;
        newObj.content.metaDescription = query.metaDescription;
        newObj.content.siteTitle = query.siteTitle;
        newObj.content.Path = query.Path;
        newObj.content.Section = query.hasOwnProperty("Section")
          ? query.Section[0].data
          : null;
        newObj.content.Pages = query.hasOwnProperty("Pages")
          ? query.Pages.map(page => page.data)
          : null;
      } else if (content.edges.length > 1) {
        // represents a static webpage.
        content.edges.forEach(({ node }) => {
          if (!node.data.ID) return; // blank row
          newObj.content[node.data.ID] = node.data.Text
            ? node.data.Text
            : node.data.Markdown
            ? node.data.Markdown.childMarkdownRemark.html
            : console.error("no content found for", node.data.ID);
        });
      }
      return newObj;
    };

    render() {
      const { data, ...props } = this.props;
      return (
        <Layout
          siteTitle={this.content.siteTitle} // these come from the table
          metaDescription={this.content.metaDescription}
          locale={props.pageContext.Locale}
          content={this.layoutContent}
        >
          <Component content={this.content} {...props} {...this.otherQueries} />
        </Layout>
      );
    }
  };
};

export default withLayout;
