import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Link from "../i18n/Link";
import guide_icon from "../images/round-info-24px.svg";
import features_icon from "../images/round-extension-24px.svg";
import pricing_icon from "../images/round-credit_card-24px.svg";
import about_icon from "../images/round-chat-24px.svg";
import { media } from "../css/theme";

const Container = styled.div`
  display: none;
  flex-direction: column;
  p {
    text-align: center;
    font-weight: 500;
    height: 20px;
  }
  nav {
    display: flex;
  }
  ${media.tablet`display: flex;`};
`;

const Icon = styled.img.attrs({
  "aria-hidden": true,
  alt: "icon"
})`
  width: 24px;
  height: 24px;
  margin-right: 4px;
`;

const ButtonContainer = styled(Link)`
  margin: 0px 8px;
  flex-shrink: 0;
  && h4 {
    font-size: 24px;
    font-weight: 300;
  }
  > div {
    display: flex;
    align-items: center;
  }
`;

const Button = ({ to, icon, text, setCaption, clearCaption }) => (
  <ButtonContainer to={to}>
    <div onMouseOver={setCaption} onMouseLeave={clearCaption}>
      <Icon src={icon} />
      <h4>{text}</h4>
    </div>
  </ButtonContainer>
);

Button.propTypes = {
  to: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  setCaption: PropTypes.func.isRequired,
  clearCaption: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired
};

class DesktopMenu extends React.Component {
  state = {
    caption: ""
  };

  setCaption = caption => {
    this.setState({ caption });
  };

  clearCaption = () => {
    this.setState({ caption: "" });
  };

  render() {
    const { content } = this.props;
    return (
      <Container>
        <nav>
          <Button
            to="/features"
            setCaption={() => this.setCaption(content.features_btn_caption)}
            clearCaption={this.clearCaption}
            icon={features_icon}
            text={content.features_btn}
          />
          <Button
            to="/pricing"
            setCaption={() => this.setCaption(content.pricing_btn_caption)}
            clearCaption={this.clearCaption}
            icon={pricing_icon}
            text={content.pricing_btn}
          />
          <Button
            to="/guide"
            setCaption={() => this.setCaption(content.guide_btn_caption)}
            clearCaption={this.clearCaption}
            icon={guide_icon}
            text={content.guide_btn}
          />
          <Button
            to="/about"
            setCaption={() => this.setCaption(content.about_btn_caption)}
            clearCaption={this.clearCaption}
            icon={about_icon}
            text={content.about_btn}
          />
        </nav>
        <p>{this.state.caption}</p>
      </Container>
    );
  }
}

export default DesktopMenu;
DesktopMenu.propTypes = {
  content: PropTypes.shape({
    guide_btn: PropTypes.string.isRequired,
    guide_btn_caption: PropTypes.string.isRequired,
    features_btn: PropTypes.string.isRequired,
    features_btn_caption: PropTypes.string.isRequired,
    pricing_btn_caption: PropTypes.string.isRequired,
    pricing_btn: PropTypes.string.isRequired,
    about_btn: PropTypes.string.isRequired,
    about_btn_caption: PropTypes.string.isRequired
  })
};
